import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home')
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my')
  },
  {
    path: '/industryList',
    name: 'industryList',
    component: () => import('@/views/industry/list')
  },
  {
    path: '/industryDetail',
    name: 'industryDetail',
    component: () => import('@/views/industry/detail')
  }
  ,
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('@/views/news/detail')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
